<template>
	<div class="exhibition">
		<div ref="boothWrapper" class="booth-wrapper">
			<div v-if="booth.template">
				<div
					ref="booth"
					class="booth"
					:class="[`is-${booth.template}`, { booth3d: !isNone, 'is-loading': boothLoading }]"
					:style="isNone && { backgroundImage: `url('${$options.filters.imageUrl(booth.booth_replacement)}')`, width: noneWidth, height: noneHeight }"
				>
					<div v-if="!customHideLogo" class="booth-logo" :class="[`is-${booth.template}`]" :style="customBoothLogo" @click="resetBooth">
						<img :src="booth.logo | imageUrl" :alt="booth.title" />
					</div>
					<a v-if="!isNone" class="booth-fullscreen" :class="{ 'is-active': fullscreen }" @click="toggleFullscreen()">
						<span v-if="!fullscreen">Full screen</span>
						<inline-svg v-if="!fullscreen" :src="require('../assets/full_screen.svg')" width="28"></inline-svg>
						<strong v-if="fullscreen">Close full screen <span>&times;</span></strong>
					</a>
					<div v-if="!isNone" class="booth-activities">
						<ul>
							<li v-for="activity in booth.activities">
								<ContentModalWrapper
									:data="activity"
									:analytics="{
										event: 'gaEvent',
										eCategory: 'Booth Event',
										eAction: 'Call To Action',
										eLabel: activity.title ? activity.title : activity.subtitle,
										Exhibitor: booth.exhibitor ? booth.exhibitor.title : undefined,
										Booth: booth.title ? booth.title : undefined,
									}"
									:class="{ 'is-restricted': activity.restricted }"
									:style="customColorActivities"
									:custom-style="customColorActivities"
									@click="actionClick(activity)"
								>
									<h2>{{ activity.title }}</h2>
									<p>{{ activity.subtitle }}</p>
									<div v-if="activity.link || activity.content_modal" class="arrow">
										<inline-svg v-if="!fullscreen" :src="require('../assets/arrow.svg')" width="25" :style="customColorActivities"></inline-svg>
									</div>
								</ContentModalWrapper>
							</li>
						</ul>
					</div>
					<div v-if="!isNas && cardExchangeActive" class="booth-cards" @click="openCardExchange">
						<inline-svg :src="require('../assets/handshake.svg')" width="36"></inline-svg>
						<span>Business card exchange</span>
					</div>
					<div v-if="booth && !booth.wasRated && ratingActive" class="booth-rating" :class="{ 'is-nas': isNas }" @click="openRating">
						<inline-svg :src="require('../assets/rating.svg')" width="36"></inline-svg>
						<span>Rate & review</span>
					</div>
					<div v-if="isNone && booth.cta" class="booth-none-cta">
						<Link
							:link="booth.cta"
							class="button is-primary"
							:external-warning="true"
							:analytics="{
								Exhibitor: booth.exhibitor ? booth.exhibitor.title : undefined,
								Booth: booth.title,
								event: 'gaEvent',
								eCategory: 'Booth Event',
								eAction: 'element_1',
								eLabel: 'Click',
							}"
						>
							{{ booth.cta.text }}
						</Link>
					</div>
					<div class="booth-return" :class="[`is-${booth.template}`]">
						<router-link v-if="!fullscreen" :to="{ name: isNas ? 'NasVillage' : 'Exhibition' }">
							<inline-svg :src="require('../assets/back.svg')" width="28"></inline-svg>
							<span>Back to {{ isNas ? 'NAS Village' : 'Exhibition' }}</span>
						</router-link>
					</div>
					<div v-if="booth.secondary_logo" class="booth-logo-exhibitor">
						<img :src="booth.secondary_logo | imageUrl" :alt="booth.exhibitor.title" />
					</div>
					<div v-if="elementVisible('topbanner')" class="topbanner" :class="[`is-${booth.template}`]">
						<img v-if="booth.nas_flag" :src="booth.nas_flag | imageUrl" />
						<strong>{{ booth.exhibitor.title }}</strong>
					</div>
					<template v-if="isDeprecated">
						<div
							v-if="element1 && elementVisible('banner1')"
							class="banner1"
							:style="{ backgroundImage: `url('${$options.filters.imageUrl(element1.banner)}')` }"
							:class="[
								`is-${booth.template}`,
								{
									'is-hovered': bannerHighlighted === 1,
									'is-other-hovered': bannerHighlighted && bannerHighlighted !== 1,
									'is-restricted': element1.restricted,
									'is-none': element1.type === 'none',
								},
							]"
							@mouseenter="highlightBanner(1)"
							@mouseleave="removeHighlight"
							@click="elementClick(element1)"
						></div>
						<div
							v-if="element2 && elementVisible('banner2')"
							class="banner2"
							:style="{ backgroundImage: `url('${$options.filters.imageUrl(element2.banner)}')` }"
							:class="[
								`is-${booth.template}`,
								{
									'is-hovered': bannerHighlighted === 2,
									'is-other-hovered': bannerHighlighted && bannerHighlighted !== 2,
									'is-restricted': element2.restricted,
									'is-none': element2.type === 'none',
								},
							]"
							@mouseenter="highlightBanner(2)"
							@mouseleave="removeHighlight"
							@click="elementClick(element2)"
						></div>
						<div
							v-if="element2 && elementVisible('banner2e')"
							class="banner2a"
							:style="{ backgroundImage: `url('${$options.filters.imageUrl(element2.banner)}')` }"
							:class="[
								`is-${booth.template}`,
								{
									'is-hovered': bannerHighlighted === 2,
									'is-other-hovered': bannerHighlighted && bannerHighlighted !== 2,
									'is-restricted': element2.restricted,
									'is-none': element2.type === 'none',
								},
							]"
							@mouseenter="highlightBanner(2)"
							@mouseleave="removeHighlight"
							@click="elementClick(element2)"
						></div>
						<div
							v-if="element2 && elementVisible('banner2e')"
							class="banner2b"
							:style="{ backgroundImage: `url('${$options.filters.imageUrl(element2.banner)}')` }"
							:class="[
								`is-${booth.template}`,
								{
									'is-hovered': bannerHighlighted === 2,
									'is-other-hovered': bannerHighlighted && bannerHighlighted !== 2,
									'is-restricted': element2.restricted,
									'is-none': element2.type === 'none',
								},
							]"
							@mouseenter="highlightBanner(2)"
							@mouseleave="removeHighlight"
							@click="elementClick(element2)"
						></div>
						<div
							v-if="element2 && elementVisible('banner2e')"
							class="banner2c"
							:style="{ backgroundImage: `url('${$options.filters.imageUrl(element2.banner)}')` }"
							:class="[
								`is-${booth.template}`,
								{
									'is-hovered': bannerHighlighted === 2,
									'is-other-hovered': bannerHighlighted && bannerHighlighted !== 2,
									'is-restricted': element2.restricted,
									'is-none': element2.type === 'none',
								},
							]"
							@mouseenter="highlightBanner(2)"
							@mouseleave="removeHighlight"
							@click="elementClick(element2)"
						></div>
						<div
							v-if="element3 && elementVisible('banner3')"
							class="banner3"
							:style="{ backgroundImage: `url('${$options.filters.imageUrl(element3.banner)}')` }"
							:class="[
								`is-${booth.template}`,
								{
									'is-hovered': bannerHighlighted === 3,
									'is-other-hovered': bannerHighlighted && bannerHighlighted !== 3,
									'is-restricted': element3.restricted,
									'is-none': element3.type === 'none',
								},
							]"
							@mouseenter="highlightBanner(3)"
							@mouseleave="removeHighlight"
							@click="elementClick(element3)"
						></div>
						<div
							v-if="element3 && elementVisible('banner3e')"
							class="banner3a"
							:style="{ backgroundImage: `url('${$options.filters.imageUrl(element3.banner)}')` }"
							:class="[
								`is-${booth.template}`,
								{
									'is-hovered': bannerHighlighted === 3,
									'is-other-hovered': bannerHighlighted && bannerHighlighted !== 3,
									'is-restricted': element3.restricted,
									'is-none': element3.type === 'none',
								},
							]"
							@mouseenter="highlightBanner(3)"
							@mouseleave="removeHighlight"
							@click="elementClick(element3)"
						></div>
						<div
							v-if="element3 && elementVisible('banner3e')"
							class="banner3b"
							:style="{ backgroundImage: `url('${$options.filters.imageUrl(element3.banner)}')` }"
							:class="[
								`is-${booth.template}`,
								{
									'is-hovered': bannerHighlighted === 3,
									'is-other-hovered': bannerHighlighted && bannerHighlighted !== 3,
									'is-restricted': element3.restricted,
									'is-none': element3.type === 'none',
								},
							]"
							@mouseenter="highlightBanner(3)"
							@mouseleave="removeHighlight"
							@click="elementClick(element3)"
						></div>
						<div
							v-if="element3 && elementVisible('banner3e')"
							class="banner3c"
							:style="{ backgroundImage: `url('${$options.filters.imageUrl(element3.banner)}')` }"
							:class="[
								`is-${booth.template}`,
								{
									'is-hovered': bannerHighlighted === 3,
									'is-other-hovered': bannerHighlighted && bannerHighlighted !== 3,
									'is-restricted': element3.restricted,
									'is-none': element3.type === 'none',
								},
							]"
							@mouseenter="highlightBanner(3)"
							@mouseleave="removeHighlight"
							@click="elementClick(element3)"
						></div>
						<div
							v-if="element4 && elementVisible('banner4')"
							class="banner4"
							:style="{ backgroundImage: `url('${$options.filters.imageUrl(element4.banner)}')` }"
							:class="[
								`is-${booth.template}`,
								{
									'is-hovered': bannerHighlighted === 4,
									'is-other-hovered': bannerHighlighted && bannerHighlighted !== 4,
									'is-restricted': element4.restricted,
									'is-none': element4.type === 'none',
								},
							]"
							@mouseenter="highlightBanner(4)"
							@mouseleave="removeHighlight"
							@click="elementClick(element4)"
						></div>
						<div
							v-if="element4 && elementVisible('banner4e')"
							class="banner4a"
							:style="{ backgroundImage: `url('${$options.filters.imageUrl(element4.banner)}')` }"
							:class="[
								`is-${booth.template}`,
								{
									'is-hovered': bannerHighlighted === 4,
									'is-other-hovered': bannerHighlighted && bannerHighlighted !== 4,
									'is-restricted': element4.restricted,
									'is-none': element4.type === 'none',
								},
							]"
							@mouseenter="highlightBanner(4)"
							@mouseleave="removeHighlight"
							@click="elementClick(element4)"
						></div>
						<div
							v-if="element4 && elementVisible('banner4e')"
							class="banner4b"
							:style="{ backgroundImage: `url('${$options.filters.imageUrl(element4.banner)}')` }"
							:class="[
								`is-${booth.template}`,
								{
									'is-hovered': bannerHighlighted === 4,
									'is-other-hovered': bannerHighlighted && bannerHighlighted !== 4,
									'is-restricted': element4.restricted,
									'is-none': element4.type === 'none',
								},
							]"
							@mouseenter="highlightBanner(4)"
							@mouseleave="removeHighlight"
							@click="elementClick(element4)"
						></div>
						<div
							v-if="element4 && elementVisible('banner4e')"
							class="banner4c"
							:style="{ backgroundImage: `url('${$options.filters.imageUrl(element4.banner)}')` }"
							:class="[
								`is-${booth.template}`,
								{
									'is-hovered': bannerHighlighted === 4,
									'is-other-hovered': bannerHighlighted && bannerHighlighted !== 4,
									'is-restricted': element4.restricted,
									'is-none': element4.type === 'none',
								},
							]"
							@mouseenter="highlightBanner(4)"
							@mouseleave="removeHighlight"
							@click="elementClick(element4)"
						></div>
						<div
							v-if="element5 && elementVisible('banner5')"
							class="banner5"
							:style="{ backgroundImage: `url('${$options.filters.imageUrl(element5.banner)}')` }"
							:class="[
								`is-${booth.template}`,
								{
									'is-hovered': bannerHighlighted === 5,
									'is-other-hovered': bannerHighlighted && bannerHighlighted !== 5,
									'is-restricted': element5.restricted,
									'is-none': element5.type === 'none',
								},
							]"
							@mouseenter="highlightBanner(5)"
							@mouseleave="removeHighlight"
							@click="elementClick(element5)"
						></div>

						<div
							v-if="element5 && elementVisible('banner5e')"
							class="banner5a"
							:style="{ backgroundImage: `url('${$options.filters.imageUrl(element5.banner)}')` }"
							:class="[
								`is-${booth.template}`,
								{
									'is-hovered': bannerHighlighted === 5,
									'is-other-hovered': bannerHighlighted && bannerHighlighted !== 5,
									'is-restricted': element5.restricted,
									'is-none': element5.type === 'none',
								},
							]"
							@mouseenter="highlightBanner(5)"
							@mouseleave="removeHighlight"
							@click="elementClick(element5)"
						></div>
						<div
							v-if="element5 && elementVisible('banner5e')"
							class="banner5b"
							:style="{ backgroundImage: `url('${$options.filters.imageUrl(element5.banner)}')` }"
							:class="[
								`is-${booth.template}`,
								{
									'is-hovered': bannerHighlighted === 5,
									'is-other-hovered': bannerHighlighted && bannerHighlighted !== 5,
									'is-restricted': element5.restricted,
									'is-none': element5.type === 'none',
								},
							]"
							@mouseenter="highlightBanner(5)"
							@mouseleave="removeHighlight"
							@click="elementClick(element5)"
						></div>
						<div
							v-if="element5 && elementVisible('banner5e')"
							class="banner5c"
							:style="{ backgroundImage: `url('${$options.filters.imageUrl(element5.banner)}')` }"
							:class="[
								`is-${booth.template}`,
								{
									'is-hovered': bannerHighlighted === 5,
									'is-other-hovered': bannerHighlighted && bannerHighlighted !== 5,
									'is-restricted': element5.restricted,
									'is-none': element5.type === 'none',
								},
							]"
							@mouseenter="highlightBanner(5)"
							@mouseleave="removeHighlight"
							@click="elementClick(element5)"
						></div>
						<div
							v-if="element5 && elementVisible('banner5e')"
							class="banner5d"
							:style="{ backgroundImage: `url('${$options.filters.imageUrl(element5.banner)}')` }"
							:class="[
								`is-${booth.template}`,
								{
									'is-hovered': bannerHighlighted === 5,
									'is-other-hovered': bannerHighlighted && bannerHighlighted !== 5,
									'is-restricted': element5.restricted,
									'is-none': element5.type === 'none',
								},
							]"
							@mouseenter="highlightBanner(5)"
							@mouseleave="removeHighlight"
							@click="elementClick(element5)"
						></div>
						<div
							v-if="element5 && elementVisible('banner5e')"
							class="banner5e"
							:style="{ backgroundImage: `url('${$options.filters.imageUrl(element5.banner)}')` }"
							:class="[
								`is-${booth.template}`,
								{
									'is-hovered': bannerHighlighted === 5,
									'is-other-hovered': bannerHighlighted && bannerHighlighted !== 5,
									'is-restricted': element5.restricted,
									'is-none': element5.type === 'none',
								},
							]"
							@mouseenter="highlightBanner(5)"
							@mouseleave="removeHighlight"
							@click="elementClick(element5)"
						></div>
						<div
							v-if="element5 && elementVisible('banner5e')"
							class="banner5f"
							:style="{ backgroundImage: `url('${$options.filters.imageUrl(element5.banner)}')` }"
							:class="[
								`is-${booth.template}`,
								{
									'is-hovered': bannerHighlighted === 5,
									'is-other-hovered': bannerHighlighted && bannerHighlighted !== 5,
									'is-restricted': element5.restricted,
									'is-none': element5.type === 'none',
								},
							]"
							@mouseenter="highlightBanner(5)"
							@mouseleave="removeHighlight"
							@click="elementClick(element5)"
						></div>
						<div
							v-if="element5 && elementVisible('banner5e')"
							class="banner5g"
							:style="{ backgroundImage: `url('${$options.filters.imageUrl(element5.banner)}')` }"
							:class="[
								`is-${booth.template}`,
								{
									'is-hovered': bannerHighlighted === 5,
									'is-other-hovered': bannerHighlighted && bannerHighlighted !== 5,
									'is-restricted': element5.restricted,
									'is-none': element5.type === 'none',
								},
							]"
							@mouseenter="highlightBanner(5)"
							@mouseleave="removeHighlight"
							@click="elementClick(element5)"
						></div>
						<div
							v-if="element5 && elementVisible('banner5e')"
							class="banner5h"
							:style="{ backgroundImage: `url('${$options.filters.imageUrl(element5.banner)}')` }"
							:class="[
								`is-${booth.template}`,
								{
									'is-hovered': bannerHighlighted === 5,
									'is-other-hovered': bannerHighlighted && bannerHighlighted !== 5,
									'is-restricted': element5.restricted,
									'is-none': element5.type === 'none',
								},
							]"
							@mouseenter="highlightBanner(5)"
							@mouseleave="removeHighlight"
							@click="elementClick(element5)"
						></div>
						<div
							v-if="element5 && elementVisible('banner5e')"
							class="banner5i"
							:style="{ backgroundImage: `url('${$options.filters.imageUrl(element5.banner)}')` }"
							:class="[
								`is-${booth.template}`,
								{
									'is-hovered': bannerHighlighted === 5,
									'is-other-hovered': bannerHighlighted && bannerHighlighted !== 5,
									'is-restricted': element5.restricted,
									'is-none': element5.type === 'none',
								},
							]"
							@mouseenter="highlightBanner(5)"
							@mouseleave="removeHighlight"
							@click="elementClick(element5)"
						></div>

						<div
							v-if="element6 && elementVisible('banner6')"
							class="banner6"
							:style="{ backgroundImage: `url('${$options.filters.imageUrl(element6.banner)}')` }"
							:class="[
								`is-${booth.template}`,
								{
									'is-hovered': bannerHighlighted === 6,
									'is-other-hovered': bannerHighlighted && bannerHighlighted !== 6,
									'is-restricted': element6.restricted,
									'is-none': element6.type === 'none',
								},
							]"
							@mouseenter="highlightBanner(6)"
							@mouseleave="removeHighlight"
							@click="elementClick(element6)"
						></div>
						<div
							v-if="element7 && elementVisible('banner7')"
							class="banner7"
							:style="{ backgroundImage: `url('${$options.filters.imageUrl(element7.banner)}')` }"
							:class="[
								`is-${booth.template}`,
								{
									'is-hovered': bannerHighlighted === 7,
									'is-other-hovered': bannerHighlighted && bannerHighlighted !== 7,
									'is-restricted': element7.restricted,
									'is-none': element7.type === 'none',
								},
							]"
							@mouseenter="highlightBanner(7)"
							@mouseleave="removeHighlight"
							@click="elementClick(element7)"
						></div>
					</template>
					<template v-else>
						<template v-for="n in 8">
							<BoothElementBanner
								v-if="elementVisible('banner', n)"
								:key="n"
								:index="n"
								:elements="booth.boothElements"
								:template="booth.template"
								:highlighted="bannerHighlighted"
								@enter="highlightBanner($event)"
								@leave="removeHighlight"
								@banner-click="elementClick($event)"
							/>
						</template>
					</template>
					<template v-for="n in 7">
						<div
							v-if="getElement(n) && elementVisible('link', n) && !getElement(n).restricted && getElement(n).type !== 'none'"
							:class="[`link${n}`, `is-${booth.template}`, customElementTitleClass, { 'is-hovered': bannerHighlighted === n }]"
							@click="elementClick(n)"
							@mouseenter="highlightBanner(n)"
							@mouseleave="removeHighlight"
						>
							<span>{{ getElement(n).title }}</span>
						</div>
					</template>

					<div
						v-if="element6 && elementVisible('icon1')"
						class="icon1"
						:class="[`is-${booth.template}`, { 'is-hovered': bannerHighlighted === 6 }]"
						@click="elementClick(element6)"
						@mouseenter="highlightBanner(6)"
						@mouseleave="removeHighlight"
					>
						<inline-svg v-if="element6.banner && element6.banner.mime === 'image/svg+xml'" :src="element6.banner | imageUrl" :style="customColorIcon" width="50"></inline-svg>
						<img v-else :src="element6.banner | imageUrl" class="icon1-img" />
					</div>
					<div
						v-if="elementVisible('link-chat') && hasTidio && !fullscreen"
						class="link-chat"
						:class="[`is-${booth.template}`, customElementTitleClass, { 'is-hovered': bannerHighlighted === 'chat' }]"
						@click="openChat"
						@mouseenter="highlightBanner('chat')"
						@mouseleave="removeHighlight"
					>
						<span>Live chat</span>
					</div>
					<a
						v-if="elementVisible('icon-chat') && hasTidio && !fullscreen"
						class="icon-chat"
						:class="[{ 'is-hovered': bannerHighlighted === 'chat', 'is-other-hovered': bannerHighlighted && bannerHighlighted !== 'chat' }, `is-${booth.template}`]"
						@click="openChat"
						@mouseenter="highlightBanner('chat')"
						@mouseleave="removeHighlight"
					>
						<inline-svg :src="require('../assets/chat.svg')"></inline-svg>
						<span>Live chat</span>
					</a>
					<a
						v-if="elementVisible('icon-chat') && hasExternalChat && !fullscreen"
						class="icon-chat"
						:class="[{ 'is-hovered': bannerHighlighted === 'chat', 'is-other-hovered': bannerHighlighted && bannerHighlighted !== 'chat' }, `is-${booth.template}`]"
						@click="openExternalChat"
						@mouseenter="highlightBanner('chat')"
						@mouseleave="removeHighlight"
					>
						<inline-svg :src="require('../assets/chat.svg')"></inline-svg>
						<span>Contact Us</span>
					</a>

					<div v-if="elementVisible('person') && (hasTidio || hasExternalChat)" class="person" :class="[`is-${booth.template}`]"></div>
					<div v-if="boothLoading" class="booth-loader" :class="{ 'is-hiding': boothPreloaded }">
						<div class="loader-box">
							<div class="loader-circle"></div>
							<div class="loader-line-mask"><div class="loader-line"></div></div>
							<img src="/loader_logo.png" :alt="platformSettings.siteName" />
						</div>
					</div>
					<transition name="scroll-icon-fade">
						<div v-if="showScrollIcon" class="mouse-scroll">
							<div class="mouse-scroll__icon"></div>
							<p class="mouse-scroll__text">Scroll</p>
						</div>
					</transition>
				</div>
				<ContentModal ref="contentModal" :element="activeElement" />
				<RestrictedModal />
				<CardExchange />
				<BoothRating />
				<BlockedModal />
				<div
					class="booth-mobile"
					:class="[`is-${booth.template}`]"
					:style="booth.booth_replacement && { backgroundImage: `url('${$options.filters.imageUrl(booth.booth_replacement)}')` }"
				>
					<div v-if="!customHideLogo" class="booth-logo" :class="[`is-${booth.template}`]" :style="customBoothLogo" @click="resetBooth">
						<img :src="booth.logo | imageUrl" :alt="booth.title" />
					</div>
					<ul>
						<li v-for="element in booth.boothElements">
							<a v-if="element.element !== 'element_side'" @click="elementClick(element)"
								><span>{{ element.title }}</span></a
							>
						</li>
					</ul>
					<div v-if="isNone && booth.cta" class="booth-none-cta is-mobile">
						<Link
							:link="booth.cta"
							class="button is-primary"
							:external-warning="true"
							:analytics="{
								Exhibitor: booth.exhibitor ? booth.exhibitor.title : undefined,
								Booth: booth.title,
								event: 'gaEvent',
								eCategory: 'Booth Event',
								eAction: 'element_1',
								eLabel: 'Click',
							}"
						>
							{{ booth.cta.text }}
						</Link>
					</div>
				</div>
			</div>
		</div>
		<div ref="boothContent" class="exhibition-content">
			<div class="exhibition-article">
				<h2 v-if="article" class="is-title" v-html="$options.filters.upperIndex(article.name)"></h2>
				<div class="article-content-wrapper is-booth">
					<aside v-if="!isNas" class="article-aside">
						<!-- Articles -->
						<div v-if="articles && articles.length > 1" class="article-submenu__menu-section">
							<h3>Articles</h3>
							<ul class="article-submenu">
								<li
									v-for="item in articles"
									:key="item.id"
									:style="[customColorMenu, item.url === article.url && customColorMenuBackground]"
									:class="{ 'is-active': item.url === article.url }"
								>
									<component
										:is="!item.restricted ? 'router-link' : 'span'"
										:to="{ name: 'Booth', params: { article: item.url } }"
										:style="item.url !== article.url && customColorMenuLink"
										class="article-submenu__item-inner"
										@click="actionClick(item)"
									>
										<span class="article-submenu__icon-wrapper">
											<inline-svg :src="require('../assets/article.svg')" class="article-submenu__icon" :style="item.url !== article.url && customColorMenuLink"></inline-svg>
										</span>
										<span class="article-submenu__item-text">
											{{ item.name }}
										</span>
									</component>
								</li>
							</ul>
						</div>

						<!-- External Links -->
						<div v-if="booth.external_links && booth.external_links.length > 0" class="article-submenu__menu-section">
							<h3>External links</h3>
							<ul class="article-submenu">
								<li v-for="item in booth.external_links" :key="item.id" :style="[customColorMenu, item.url === article.url && customColorMenuBackground]">
									<Link
										:link="item"
										:analytics="{
											event: 'gaEvent',
											eCategory: 'Booth Event',
											eAction: 'element_side',
											eLabel: item.text ? item.text : null,
											Exhibitor: booth.exhibitor ? booth.exhibitor.title : undefined,
											Booth: booth.title ? booth.title : undefined,
										}"
										class-name="article-submenu__item-inner"
										:style="item.url !== article.url && customColorMenuLink"
									>
										<span class="article-submenu__icon-wrapper">
											<inline-svg :src="require('../assets/external-link.svg')" class="article-submenu__icon" :style="item.url !== article.url && customColorMenuLink"></inline-svg>
										</span>
										<span class="article-submenu__item-text">
											{{ item.text }}
										</span>
									</Link>
								</li>
							</ul>
						</div>

						<!-- Documents -->
						<div v-if="sideMenuElements && sideMenuElements.length > 0" class="article-submenu__menu-section">
							<h3>Documents</h3>
							<ul class="article-submenu">
								<li v-for="item in sideMenuElements" :key="`be-${item.id}`" :style="[customColorMenu, item.url === article.url && customColorMenuBackground]">
									<a class="article-submenu__item-inner" :style="item.url !== article.url && customColorMenuLink" @click.prevent="elementClick(item)">
										<span class="article-submenu__icon-wrapper">
											<inline-svg
												v-if="item.type === 'link_external'"
												:src="require('../assets/external-link.svg')"
												class="article-submenu__icon"
												:style="item.url !== article.url && customColorMenuLink"
											></inline-svg>
											<inline-svg
												v-else-if="item.type === 'pdf_local'"
												:src="require('../assets/pdf.svg')"
												class="article-submenu__icon"
												:style="item.url !== article.url && customColorMenuLink"
											></inline-svg>
											<inline-svg
												v-else-if="item.url && item.url.includes('vimeo') && item.type === 'youtube'"
												:src="require('../assets/vimeo.svg')"
												class="article-submenu__icon"
												:style="item.url !== article.url && customColorMenuLink"
											></inline-svg>
											<inline-svg
												v-else-if="item.url && item.type === 'youtube'"
												:src="require('../assets/youtube-2.svg')"
												class="article-submenu__icon"
												:style="item.url !== article.url && customColorMenuLink"
											></inline-svg>
											<inline-svg
												v-else-if="item.type === 'video_external' || item.type === 'video_local'"
												:src="require('../assets/video.svg')"
												class="article-submenu__icon"
												:style="item.url !== article.url && customColorMenuLink"
											></inline-svg>
											<inline-svg
												v-else-if="item.type === 'image_external' || item.type === 'image_local'"
												:src="require('../assets/image.svg')"
												class="article-submenu__icon"
												:style="item.url !== article.url && customColorMenuLink"
											></inline-svg>
											<inline-svg
												v-else-if="item.type === 'booth_article'"
												:src="require('../assets/article.svg')"
												class="article-submenu__icon"
												:style="item.url !== article.url && customColorMenuLink"
											></inline-svg>
											<inline-svg v-else :src="require('../assets/folder.svg')" class="article-submenu__icon" :style="item.url !== article.url && customColorMenuLink"></inline-svg>
										</span>
										<span class="article-submenu__item-text">
											{{ item.title }}
										</span>
									</a>
								</li>
							</ul>
						</div>

						<!-- Exhibitor -->
						<div v-if="cardExchangeActive || (booth && !booth.wasRated && ratingActive)" class="article-submenu__menu-section">
							<h3>Exhibitor</h3>
							<ul class="article-submenu article-submenu--exhibitor">
								<li v-if="cardExchangeActive" :style="customColorMenu">
									<a :style="{ ...customColorMenuLink, ...customColorMenu }" class="article-submenu__item-inner" @click.prevent="openCardExchange">
										<inline-svg :src="require('../assets/handshake.svg')" width="22"></inline-svg>
										<span class="article-submenu__item-text">
											Business card exchange
										</span>
									</a>
								</li>
								<li v-if="booth && !booth.wasRated && ratingActive" :style="customColorMenu">
									<a :style="{ ...customColorMenuLink, ...customColorMenu }" class="article-submenu__item-inner" @click.prevent="openRating">
										<span class="article-submenu__icon-wrapper">
											<inline-svg :src="require('../assets/rating.svg')" width="22"></inline-svg>
										</span>
										<span class="article-submenu__item-text">
											Rate & review
										</span>
									</a>
								</li>
							</ul>
						</div>
						<div v-if="bannerLeft" class="booth-article-banner-left">
							<BoothBanner
								:data="bannerLeft"
								:exhibitor="booth.exhibitor"
								:booth-title="booth.title"
								:analytics="{
									event: 'gaEvent',
									eCategory: 'Booth Event',
									eAction: 'Left Banner',
									eLabel: bannerLeft.name ? bannerLeft.name : bannerLeft.link ? bannerLeft.link.url : null,
									Exhibitor: booth.exhibitor ? booth.exhibitor.title : undefined,
									Booth: booth.title ? booth.title : undefined,
								}"
							/>
						</div>
						<div v-if="socials && socials.length > 0 && socials[0].social_item && socials[0].social_item.length > 0" class="booth-socials">
							<h3>Follow us</h3>
							<template v-for="socialGroup in socials">
								<span v-if="socialGroup.subtitle">{{ socialGroup.subtitle }}</span>
								<ul>
									<li v-for="social in socialGroup.social_item">
										<Link v-if="social.type === 'twitter'" :link="{ type: 'external', url: social.url, target: true }" :external-warning="true" :style="customColorMenuBackground"
											><inline-svg :src="require('../assets/twitter.svg')" width="25"></inline-svg
										></Link>
										<Link v-if="social.type === 'facebook'" :link="{ type: 'external', url: social.url, target: true }" :external-warning="true" :style="customColorMenuBackground"
											><inline-svg :src="require('../assets/facebook.svg')" width="25" fill="red"></inline-svg
										></Link>
										<Link v-if="social.type === 'linkedin'" :link="{ type: 'external', url: social.url, target: true }" :external-warning="true" :style="customColorMenuBackground"
											><inline-svg :src="require('../assets/linkedin.svg')" width="25"></inline-svg
										></Link>
										<Link v-if="social.type === 'instagram'" :link="{ type: 'external', url: social.url, target: true }" :external-warning="true" :style="customColorMenuBackground"
											><inline-svg :src="require('../assets/instagram.svg')" width="25"></inline-svg
										></Link>
										<Link v-if="social.type === 'website'" :link="{ type: 'external', url: social.url, target: true }" :external-warning="true" :style="customColorMenuBackground"
											><inline-svg :src="require('../assets/globe.svg')" width="25"></inline-svg
										></Link>
										<Link v-if="social.type === 'youtube'" :link="{ type: 'external', url: social.url, target: true }" :external-warning="true" :style="customColorMenuBackground"
											><inline-svg :src="require('../assets/youtube.svg')" width="25"></inline-svg
										></Link>
									</li>
								</ul>
							</template>
						</div>
					</aside>
					<div class="booth-article-wrapper">
						<VueMarkdown
							v-if="article && article.content"
							ref="article"
							:source="article.content"
							class="article-content content"
							:class="{ 'has-menu-left': leftMenu }"
							:prerender="prerender"
							@rendered="rendered"
						/>
						<VueMarkdown v-if="booth.disclaimer" :source="booth.disclaimer" class="booth-disclaimer content" />
						<div v-if="!isNas && bannerBottom" class="booth-article-banner-bottom">
							<BoothBanner
								:data="bannerBottom"
								:exhibitor="booth.exhibitor"
								:booth-title="booth.title"
								:analytics="{
									event: 'gaEvent',
									eCategory: 'Booth Event',
									eAction: 'Left Banner',
									eLabel: bannerBottom.name ? bannerBottom.name : bannerBottom.link ? bannerBottom.link.url : null,
									Exhibitor: booth.exhibitor ? booth.exhibitor.title : undefined,
									Booth: booth.title ? booth.title : undefined,
								}"
							/>
						</div>
						<div v-if="isNas && socials && socials[0].social_item && socials[0].social_item.length > 0" class="booth-socials is-center">
							<h3>Follow us</h3>
							<template v-for="socialGroup in socials">
								<span v-if="socialGroup.subtitle">{{ socialGroup.subtitle }}</span>
								<ul>
									<li v-for="social in socialGroup.social_item">
										<a v-if="social.type === 'twitter'" :href="social.url" target="_blank" :style="customColorMenuBackground"
											><inline-svg :src="require('../assets/twitter.svg')" width="25"></inline-svg
										></a>
										<a v-if="social.type === 'facebook'" :href="social.url" target="_blank" :style="customColorMenuBackground"
											><inline-svg :src="require('../assets/facebook.svg')" width="25"></inline-svg
										></a>
										<a v-if="social.type === 'linkedin'" :href="social.url" target="_blank" :style="customColorMenuBackground"
											><inline-svg :src="require('../assets/linkedin.svg')" width="25"></inline-svg
										></a>
										<a v-if="social.type === 'instagram'" :href="social.url" target="_blank" :style="customColorMenuBackground"
											><inline-svg :src="require('../assets/instagram.svg')" width="25"></inline-svg
										></a>
										<a v-if="social.type === 'website'" :href="social.url" target="_blank" :style="customColorMenuBackground"
											><inline-svg :src="require('../assets/globe.svg')" width="25"></inline-svg
										></a>
										<a v-if="social.type === 'youtube'" :href="social.url" target="_blank" :style="customColorMenuBackground"
											><inline-svg :src="require('../assets/youtube.svg')" width="25"></inline-svg
										></a>
									</li>
								</ul>
							</template>
						</div>
					</div>
					<aside v-if="!isNas && bannerRight" class="booth-article-right">
						<div class="booth-article-right">
							<BoothBanner
								:data="bannerRight"
								:exhibitor="booth.exhibitor"
								:booth-title="booth.title"
								:analytics="{
									event: 'gaEvent',
									eCategory: 'Booth Event',
									eAction: 'Right Banner',
									eLabel: bannerRight.name ? bannerRight.name : bannerRight.link ? bannerRight.link.url : null,
									Exhibitor: booth.exhibitor ? booth.exhibitor.title : undefined,
									Booth: booth.title ? booth.title : undefined,
								}"
							/>
						</div>
					</aside>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Link from '../components/Link';
	import $http from '../utils/http.js';
	import ContentModal from '../components/ContentModal';
	import ExternalDisclaimer from '../components/ExternalDisclaimer';
	import linkRoute from '../utils/link';
	import session from '../utils/session';
	import VueMarkdown from 'vue-markdown-v2';
	import videojs from 'video.js';
	import ImageLoader from '../utils/imageloader';
	import { prerender } from '../utils/markdown';
	import RestrictedModal from '../components/RestrictedModal';
	import BoothRating from '../components/BoothRating';
	import CardExchange from '../components/CardExchange';
	import BlockedModal from '../components/BlockedModal';
	import platformSettings from '../utils/platformSettings';
	import meta from '../utils/meta.js';
	import BoothElementBanner from '../components/Booth/BoothElementBanner';
	import BoothBanner from '../components/Booth/BoothBanner';
	import { EVENT_NAME } from '../consts';
	import BoothDisclaimerModal from '../components/BoothDisclaimerModal';
	import ContentModalWrapper from '@/components/ContentModalWrapper';

	const elementVisibility = {
		eaaci: ['banner1', 'banner2e', 'banner3e', 'banner4e', 'banner5e', 'banner6', 'banner7', 'link1', 'link2', 'link3', 'link4', 'link5', 'link6', 'icon-chat', 'person'],
		premium: ['banner1', 'banner2', 'banner3', 'banner4', 'banner5', 'link1', 'link2', 'link3', 'link4', 'link5', 'link6', 'icon1', 'icon-chat', 'person'],
		simple: ['banner1', 'banner2', 'link1', 'link2', 'link3', 'link4', 'icon-chat', 'person'],
		nas: ['banner1', 'link1', 'link2', 'link3', 'link4', 'link5', 'topbanner'],
		eaaciv2: [
			'banner1',
			'banner2',
			'banner3',
			'banner4',
			'banner5',
			'banner6',
			'banner7',
			'banner8',
			'link1',
			'link2',
			'link3',
			'link4',
			'link5',
			'link6',
			'link7',
			'icon-chat',
			'person',
		],
		'hal-main': ['banner1', 'banner2', 'banner3', 'banner4', 'banner5', 'banner6', 'link1', 'link2', 'link3', 'link4', 'link5', 'link6'],
		aimmune: ['banner1', 'banner2', 'banner3', 'banner4', 'banner5', 'banner6', 'banner7', 'link1', 'link2', 'link3', 'link4', 'link5', 'link6', 'link7', 'icon-chat'],
		aimmune_v2: ['banner1', 'banner2', 'banner3', 'banner4', 'banner5', 'banner6', 'link1', 'link2', 'link3', 'link4', 'link5', 'link6', 'banner7', 'link7'],
		'stallergenes-greer': ['banner1', 'banner2', 'banner3', 'banner4', 'banner5', 'banner6', 'banner7', 'icon-chat'],
		'sanofi-triage': ['banner1', 'banner2', 'banner3', 'banner4', 'banner5', 'banner6', 'icon-chat'],
		'sanofi-type2': ['banner1', 'banner2', 'banner3', 'banner4', 'banner5', 'icon-chat'],
		sanofi_dupixent: ['banner1', 'banner2', 'banner3', 'banner4', 'banner5', 'banner6', 'banner7', 'banner8', 'icon-chat'],
		sanofi_medical: ['banner1', 'banner2', 'banner3', 'banner4', 'banner5', 'banner6', 'banner7', 'icon-chat'],
		'nestle-purina': ['banner1', 'banner2', 'banner3', 'banner4', 'banner5', 'banner6', 'icon-chat'],
		'csl-behring': ['banner1', 'banner2', 'banner3', 'banner4', 'banner5', 'banner6', 'link1', 'link2', 'link3', 'link4', 'link5', 'link6', 'icon-chat'],
		novartis: ['banner1', 'banner2', 'banner3', 'banner4', 'banner5', 'banner6', 'icon-chat', 'chat-hover'],
	};

	let boothElement;
	let boothWrapper;
	let isFullscreen = false;
	let initialDims = { width: 1920, height: 1272 };
	const resize = () => {
		if (boothElement && boothWrapper) {
			if (!isFullscreen) {
				let parent = boothElement.parentNode,
					parentWidth = parent.clientWidth,
					scale = parentWidth / initialDims.width,
					marginBottom = (scale - 1) * initialDims.height + 10;

				boothElement.style.transformOrigin = '0 0';
				boothElement.style.transform = 'scale(' + scale + ')';

				boothElement.style.WebkitTransformOrigin = '0 0';
				boothElement.style.WebkitTransform = 'scale(' + scale + ')';

				boothElement.style.MozTransformOrigin = '0 0';
				boothElement.style.MozTransform = 'scale(' + scale + ')';

				boothElement.style.OTransformOrigin = '0 0';
				boothElement.style.OTransform = 'scale(' + scale + ')';

				boothElement.style.marginBottom = marginBottom + 'px';
				parent.style.width = 'auto';
			} else {
				let initialDims = { width: 1920, height: 1272 };
				let cs = window.getComputedStyle(boothWrapper);
				let maxHeight = boothWrapper.clientHeight - parseFloat(cs.paddingTop) - parseFloat(cs.paddingBottom);
				let parent = boothElement.parentNode;

				parent.style.width = 'auto';

				let scale = parent.clientWidth / initialDims.width;
				let marginBottom = (scale - 1) * initialDims.height + 10;

				if (initialDims.height * scale > maxHeight) {
					scale = maxHeight / initialDims.height;
					marginBottom = (scale - 1) * initialDims.height + 10;
				}

				boothElement.style.transformOrigin = '0 0';
				boothElement.style.transform = 'scale(' + scale + ')';

				boothElement.style.WebkitTransformOrigin = '0 0';
				boothElement.style.WebkitTransform = 'scale(' + scale + ')';

				boothElement.style.MozTransformOrigin = '0 0';
				boothElement.style.MozTransform = 'scale(' + scale + ')';

				boothElement.style.OTransformOrigin = '0 0';
				boothElement.style.OTransform = 'scale(' + scale + ')';

				boothElement.style.marginBottom = marginBottom + 'px';

				parent.style.width = initialDims.width * scale + 'px';
			}
		}
	};
	window.resize = resize;
	export default {
		name: 'Booth',
		components: { ContentModalWrapper, BoothElementBanner, BoothBanner, BlockedModal, BoothRating, RestrictedModal, CardExchange, Link, ContentModal, VueMarkdown },
		filters: {
			upperIndex(text) {
				return text.replace(/&reg;/gi, '<sup>&reg;</sup>').replace(/®/gi, '<sup>&reg;</sup>');
			},
		},
		data() {
			return {
				booth: {},
				boothLoading: true,
				boothPreloaded: false,
				leftMenu: false,
				fullscreen: false,
				bannerHighlighted: null,
				activeElement: null,
				nonePadding: `${(1181 / 1950) * 100}%`,
				noneWidth: 1950,
				noneHeight: 1181,
				platformSettings: platformSettings.state,
				showScrollIcon: true,
			};
		},
		beforeRouteEnter(to, from, next) {
			$http.get(`/booths/${to.params.url}`).then(
				data => {
					if (data.data) {
						window.scrollTo(0, 0);
						next(vm => {
							let dataLayer = (window.dataLayer = window.dataLayer || []);
							dataLayer.push({
								event: 'data',
								userId: session.state.user && session.state.user.kitId ? session.state.user.kitId : undefined,
								Exhibitor: data.data.exhibitor ? data.data.exhibitor.title : undefined,
								Booth: data.data.title,
							});

							vm.$nextTick(() => {
								vm.$gtm.trackEvent({
									event: 'content-view',
									'content-name': to.path,
									'content-view-name': to.name,
								});
							});

							vm.setData(data.data);
						});
					}
				},
				() => {
					next('/');
				}
			);
		},
		beforeRouteUpdate(to, from, next) {
			if (this.booth && this.booth.url !== to.params.url) {
				window.sessionStorage.setItem('forceReload', JSON.stringify({ name: to.name, params: to.params }));
				location.reload();
			} else {
				if (to.params.url !== from.params.url) {
					$http.get(`/booths/${to.params.url}`).then(data => {
						if (data.data) {
							if (!to.params.article) {
								window.scrollTo(0, 0);
							}
							this.setData(data.data);
							next();
						}
					});
				} else if (to.params.article !== from.params.article) {
					next();
					this.$gtm.trackEvent({
						event: 'content-view',
						'content-name': to.path,
						'content-view-name': to.name,
					});
					if (this.$refs.boothContent) {
						this.$nextTick(() => {
							this.$refs.boothContent.scrollIntoView({ behavior: 'smooth' });
						});
					}
				} else if (to.params.article === from.params.article) {
					this.$refs.boothContent.scrollIntoView({ behavior: 'smooth' });
				}
			}
		},
		beforeRouteLeave(to, from, next) {
			if (window.tidioKey) {
				window.sessionStorage.setItem('forceReload', JSON.stringify({ name: to.name, params: to.params }));
				location.reload();
			} else {
				next();
			}
		},
		computed: {
			article() {
				if (!this.booth || !this.booth.articles || this.booth.articles.length === 0) return;
				if (this.$route.params.article) {
					return this.booth.articles.find(e => e.url === this.$route.params.article);
				} else {
					return this.booth.articles[0];
				}
			},
			articles() {
				if (!this.booth || !this.booth.articles || this.booth.articles.length === 0) return [];
				return this.booth.articles.sort((a, b) => {
					return a.order - b.order;
				});
			},
			bannerLeft() {
				if (!this.booth || !this.booth.banners || this.booth.banners.length === 0) return;
				let banners = this.booth.banners.filter(e => e.position === 'left');
				return banners[Math.floor(Math.random() * banners.length)];
			},
			bannerRight() {
				if (!this.booth || !this.booth.banners || this.booth.banners.length === 0) return;
				let banners = this.booth.banners.filter(e => e.position === 'right');
				return banners[Math.floor(Math.random() * banners.length)];
			},
			bannerBottom() {
				if (!this.booth || !this.booth.banners || this.booth.banners.length === 0) return;
				let banners = this.booth.banners.filter(e => e.position === 'bottom');
				return banners[Math.floor(Math.random() * banners.length)];
			},
			socials() {
				if (!this.booth || !this.booth.socials || this.booth.socials.length === 0 || (this.booth.socials[0] && this.booth.socials[0].social_item === 0)) return;
				return this.booth.socials;
			},
			element1() {
				if (!this.booth || !this.booth.boothElements || this.booth.boothElements.length === 0 || !this.booth.template) return;
				return this.booth.boothElements.find(e => e.element === 'element_1');
			},
			element2() {
				if (!this.booth || !this.booth.boothElements || this.booth.boothElements.length === 0 || !this.booth.template) return;
				return this.booth.boothElements.find(e => e.element === 'element_2');
			},
			element3() {
				if (!this.booth || !this.booth.boothElements || this.booth.boothElements.length === 0 || !this.booth.template) return;
				return this.booth.boothElements.find(e => e.element === 'element_3');
			},
			element4() {
				if (!this.booth || !this.booth.boothElements || this.booth.boothElements.length === 0 || !this.booth.template) return;
				return this.booth.boothElements.find(e => e.element === 'element_4');
			},
			element5() {
				if (!this.booth || !this.booth.boothElements || this.booth.boothElements.length === 0 || !this.booth.template) return;
				return this.booth.boothElements.find(e => e.element === 'element_5');
			},
			element6() {
				if (!this.booth || !this.booth.boothElements || this.booth.boothElements.length === 0 || !this.booth.template) return;
				return this.booth.boothElements.find(e => e.element === 'element_6');
			},
			element7() {
				if (!this.booth || !this.booth.boothElements || this.booth.boothElements.length === 0 || !this.booth.template) return;
				return this.booth.boothElements.find(e => e.element === 'element_7');
			},
			elementVisibility() {
				return elementVisibility[this.booth.template];
			},
			sideMenuElements() {
				if (!this.booth || !this.booth.boothElements || this.booth.boothElements.length === 0 || !this.booth.template) return;
				return this.booth.boothElements.filter(e => e.element === 'element_side');
			},
			isEaaci() {
				if (!this.booth) return;
				return this.booth.template === 'eaaci' || this.booth.template === 'eaaciv2';
			},
			isNas() {
				if (!this.booth) return;
				return this.booth.template === 'nas';
			},
			isNone() {
				if (!this.booth) return;
				return this.booth.template === 'none';
			},
			isDeprecated() {
				if (!this.booth) return;
				return ['eaaci', 'nas'].indexOf(this.booth.template) !== -1;
			},
			hasTidio() {
				if (!this.booth) return;
				return this.booth.live_chat_enabled && this.platformSettings.boothLiveChat && this.booth.liveChat && this.booth.liveChat.length === 32 && window.tidioKey;
			},
			hasExternalChat() {
				if (!this.booth) return;
				return !this.booth.liveChat && this.booth.external_communication_link;
			},
			cardExchangeActive() {
				if (!this.booth) return;
				return this.booth.card_exchange_enabled && this.platformSettings.boothBusinessCard;
			},
			ratingActive() {
				if (!this.booth) return;
				return this.booth.rating_enabled && this.platformSettings.boothReview;
			},
			customHideLogo() {
				if (!this.booth || !this.booth.booth_customization) return;
				return this.booth.booth_customization && this.booth.booth_customization.booth_logo === false;
			},
			customBoothLogo() {
				if (!this.booth || !this.booth.booth_customization) return;
				if (this.booth.booth_customization && this.booth.booth_customization.color_logo_background) {
					return { background: this.booth.booth_customization.color_logo_background };
				} else if (this.booth.booth_customization.color_logo_background === '') {
					return { background: 'transparent' };
				}
			},
			customColorActivities() {
				if (!this.booth || !this.booth.booth_customization) return;
				if (this.booth.booth_customization && this.booth.booth_customization.color_activities) {
					return { color: this.booth.booth_customization.color_activities, fill: this.booth.booth_customization.color_activities };
				}
			},
			customColorMenu() {
				if (!this.booth || !this.booth.booth_customization) return;
				if (this.booth.booth_customization && this.booth.booth_customization.color_element) {
					return { 'border-color': this.booth.booth_customization.color_element };
				}
			},
			customColorMenuBackground() {
				if (!this.booth || !this.booth.booth_customization) return;
				if (this.booth.booth_customization && this.booth.booth_customization.color_element) {
					return { 'background-color': this.booth.booth_customization.color_element };
				}
			},
			customColorMenuLink() {
				if (!this.booth || !this.booth.booth_customization) return;
				if (this.booth.booth_customization && this.booth.booth_customization.color_element) {
					return { color: this.booth.booth_customization.color_element };
				}
			},
			customColorIcon() {
				if (!this.booth || !this.booth.booth_customization) return;
				if (this.booth.booth_customization && this.booth.booth_customization.color_icon) {
					return { fill: this.booth.booth_customization.color_icon };
				}
			},
			customElementTitleClass() {
				if (!this.booth || !this.booth.booth_customization) return;
				if ((this.booth.booth_customization && this.booth.booth_customization.element_title) || this.booth.booth_customization.element_icon) {
					let retVal = '';
					if (this.booth.booth_customization.element_title === 'off') retVal = 'is-text-hidden';
					if (this.booth.booth_customization.element_title === 'hover') retVal = 'is-text-hover';
					if (this.booth.booth_customization.element_icon === 'off') retVal += ' is-icon-hidden';
					if (this.booth.booth_customization.element_icon === 'point') retVal += ' is-point';
					return retVal;
				}
				return false;
			},
		},
		watch: {
			$route() {
				this.closeFullscreen();
			},
		},
		beforeDestroy() {
			window.removeEventListener('resize', resize);
			document.removeEventListener('fullscreenchange', this.exitFullscreen);
			document.removeEventListener('webkitfullscreenchange', this.exitFullscreen);
			document.removeEventListener('mozfullscreenchange', this.exitFullscreen);
			document.removeEventListener('MSFullscreenChange', this.exitFullscreen);
			document.removeEventListener('tidioChat-ready', this.tidioInit);
		},
		mounted() {
			document.addEventListener('fullscreenchange', this.exitFullscreen);
			document.addEventListener('webkitfullscreenchange', this.exitFullscreen);
			document.addEventListener('mozfullscreenchange', this.exitFullscreen);
			document.addEventListener('MSFullscreenChange', this.exitFullscreen);
			if (window.tidioChatApi) {
				window.tidioChatApi.on('ready', this.tidioInit);
			} else {
				document.addEventListener('tidioChat-ready', this.tidioInit);
			}
		},
		methods: {
			setData(data) {
				this.booth = data;
				meta.setTitle(this.booth.title);
				this.$nextTick(function() {
					boothElement = this.$refs.booth;
					boothWrapper = this.$refs.boothWrapper;

					if (this.booth.template !== 'none') {
						window.addEventListener('resize', resize);
						resize();
					} else {
						let replacementImage = new Image();
						replacementImage.src = this.$options.filters.imageUrl(this.booth.booth_replacement);
						replacementImage.onload = () => {
							initialDims = { width: replacementImage.naturalWidth, height: replacementImage.naturalHeight };
							this.nonePadding = `${(replacementImage.naturalHeight / replacementImage.naturalWidth) * 100}%`;
							this.noneWidth = `${replacementImage.naturalWidth}px`;
							this.noneHeight = `${replacementImage.naturalHeight}px`;
							window.addEventListener('resize', resize);
							resize();
						};
					}

					/* Mouse scroll */
					const boothOffsetTop = boothElement.offsetTop;
					const boothHeight = boothElement.getBoundingClientRect().height;
					const scrollToHideMouseIcon = boothOffsetTop + (boothHeight / 3) * 2;

					window.addEventListener('scroll', () => {
						if (window.scrollY >= scrollToHideMouseIcon && this.showScrollIcon) {
							this.showScrollIcon = false;
						} else if (window.scrollY < scrollToHideMouseIcon && !this.showScrollIcon) {
							this.showScrollIcon = true;
						}
					});

					let preloadImages = [];

					[this.element1, this.element2, this.element3, this.element4, this.element5, this.element6, this.element7].forEach(e => {
						if (e && e.banner) {
							preloadImages.push(this.$options.filters.imageUrl(e.banner));
						}
					});

					let boothBg = this.$refs.booth.currentStyle || window.getComputedStyle(this.$refs.booth, null);
					let boothBgImg = boothBg.backgroundImage.match(/url\(["']?([^"']*)["']?\)/);
					if (boothBgImg && boothBgImg.length > 0) {
						preloadImages.push(boothBgImg[1]);
					}

					ImageLoader(preloadImages, () => {
						this.boothPreloaded = true;
						setTimeout(() => {
							this.boothLoading = false;
							this.boothPreloaded = false;
						}, 250);
					});

					if (this.booth.booth_customization) {
						if (this.booth.booth_customization.color_element) {
							document.styleSheets[0].addRule(
								'.booth .link1, .booth .link2, .booth .link3, .booth .link4, .booth .link5, .booth .link6, .booth .link7',
								'color: ' + this.booth.booth_customization.color_element + ' !important;'
							);

							document.styleSheets[0].addRule(
								'.booth .link1:after, .booth .link2:after, .booth .link3:after, .booth .link4:after, .booth .link5:after, .booth .link6:after, .booth .link7:after',
								'background: ' + this.booth.booth_customization.color_element + ' !important;'
							);

							document.styleSheets[0].addRule(
								'.booth .link1:before, .booth .link2:before, .booth .link3:before, .booth .link4:before, .booth .link5:before, .booth .link6:before, .booth .link7:before',
								'border-color: ' + this.booth.booth_customization.color_element + ' !important;'
							);

							document.styleSheets[0].addRule(
								'.article-aside h3:before, .exhibition-article h2.is-title:before',
								'background: ' + this.booth.booth_customization.color_element + ' !important;'
							);

							document.styleSheets[0].addRule('.article-aside h3:after, .exhibition-article h2.is-title:after', 'background: rgba(0,0,0,0.2) !important;');
						}

						if (this.booth.booth_customization.color_icon && this.booth.template === 'eaaciv2') {
							document.styleSheets[0].addRule('.booth3d.is-eaaciv2 .banner7 svg path', 'fill: ' + this.booth.booth_customization.color_icon + ' !important;');
						}
					}

					if (this.booth.disclaimer_popup) {
						let agreement = window.localStorage.getItem(`${EVENT_NAME}-${this.booth.url}-disclaimer`);
						if (agreement !== 'true') {
							this.$modal.show(
								BoothDisclaimerModal,
								{
									url: this.booth.url,
									disclaimer: this.booth.disclaimer_popup,
								},
								{
									classes: 'booth-disclaimer-modal',
									width: '90%',
									maxWidth: 840,
									height: 'auto',
									adaptive: true,
									clickToClose: false,
								},
								{}
							);
						}
					}
				});

				if (this.platformSettings.boothLiveChat && this.booth.live_chat_enabled && this.booth.liveChat && this.booth.liveChat.length === 32) {
					if (!window.tidioKey) {
						if (session.state.user && typeof session.state.user.kitId !== 'undefined') {
							document.tidioIdentify = {
								distinct_id: '' + session.state.user.kitId,
								email: session.state.user.email,
								username: `${session.state.user.firstName} ${session.state.user.lastName}`,
							};
						}
						window.tidioKey = this.booth.liveChat;
						let tidioScript = document.createElement('script');
						tidioScript.src = `//code.tidio.co/${this.booth.liveChat}.js`;
						document.body.appendChild(tidioScript);
					} else if (window.tidioKey && window.tidioKey !== this.booth.liveChat) {
						location.reload();
					}
				}
			},
			tidioInit() {
				window.tidioChatApi.setContactProperties({
					prescriber: session.state.user.prescriber ? 'Yes' : 'No',
					pharma_company: session.state.user.pharmaEmployee ? 'Yes' : 'No',
					profession: session.state.user.user_profession ? session.state.user.user_profession.name : '',
					country: session.state.user.user_country ? session.state.user.user_country.name : '',
				});

				window.tidioChatApi.on('open', () => {
					this.$gtm.dataLayer().push({
						event: 'gaEvent',
						eCategory: 'Booth Event',
						eAction: 'Live Chat',
						eLabel: 'open',
						Exhibitor: this.booth.exhibitor ? this.booth.exhibitor.title : undefined,
						Booth: this.booth.title ? this.booth.title : undefined,
					});

					$http.post('/create-action', {
						category_type: 'chatting',
						action_id: this.booth.id,
					});
				});
			},
			toggleFullscreen() {
				if (this.fullscreen === false) {
					this.$refs.boothWrapper.requestFullscreen().then(() => {
						resize();
					});
				} else {
					document.exitFullscreen().then(() => {
						resize();
					});
				}
				this.fullscreen = !this.fullscreen;
				isFullscreen = this.fullscreen;
			},
			closeFullscreen() {
				if (this.fullscreen === true) {
					document.exitFullscreen().then(() => {
						resize();
					});
					this.fullscreen = !this.fullscreen;
					isFullscreen = this.fullscreen;
				}
			},
			exitFullscreen() {
				if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
					this.fullscreen = false;
					isFullscreen = this.fullscreen;
					resize();
				}
			},
			highlightBanner(id) {
				if (typeof id === 'number') {
					this.bannerHighlighted = id;
				} else if (id === 'chat' && elementVisibility[this.booth.template].indexOf('chat-hover') !== -1) {
					this.bannerHighlighted = id;
				}
			},
			removeHighlight() {
				this.bannerHighlighted = null;
			},
			openChat() {
				if (window.tidioChatApi) {
					window.tidioChatApi.open();
				} else if (this.hasTidio) {
					this.$modal.show('blocked-modal');
				}
			},
			openExternalChat() {
				this.$gtm.dataLayer().push({
					event: 'gaEvent',
					eCategory: 'Booth Event',
					eAction: 'Live Chat',
					eLabel: 'External',
					Exhibitor: this.booth.exhibitor ? this.booth.exhibitor.title : undefined,
					Booth: this.booth.title ? this.booth.title : undefined,
				});

				this.$modal.show(
					ExternalDisclaimer,
					{
						url: this.booth.external_communication_link,
						target: true,
					},
					{
						classes: 'external-disclaimer',
						width: '90%',
						maxWidth: 550,
						height: 'auto',
						adaptive: true,
					}
				);
			},
			elementVisible(name, index) {
				if (!this.booth.template || !elementVisibility[this.booth.template]) return;
				if (index) return elementVisibility[this.booth.template].indexOf(`${name}${index}`) !== -1;
				return elementVisibility[this.booth.template].indexOf(name) !== -1;
			},
			actionClick(activity) {
				if (!this.booth) return;
				if (activity.restricted === true) {
					this.$modal.show('restrict-modal');
					window.setTimeout(() => {
						this.$modal.hide('restrict-modal');
					}, 3500);
				}
			},
			elementClick(element) {
				if (!this.booth || !this.booth.boothElements || this.booth.boothElements.length === 0) return;
				if (typeof element === 'number') {
					element = this.booth.boothElements.find(e => e.element === `element_${element}`);
				}
				if (element.restricted === true) {
					this.$modal.show('restrict-modal');
					window.setTimeout(() => {
						this.$modal.hide('restrict-modal');
					}, 3500);
				} else {
					if (element.type === 'link_external') {
						this.$modal.show(
							ExternalDisclaimer,
							{
								url: element.url,
								target: element.target,
							},
							{
								classes: 'external-disclaimer',
								width: '90%',
								maxWidth: 550,
								height: 'auto',
								adaptive: true,
							}
						);
					} else if (element.type === 'safelink') {
						if (element.safelink.type === 'external') {
							if (element.safelink.target === true) {
								window.open(element.safelink.url, '_blank');
							} else {
								window.location = element.safelink.url;
							}
						} else {
							this.$router.push(linkRoute(element.safelink.type, element.safelink.url));
						}
					} else if (element.type === 'booth_article') {
						this.$router.push({ name: 'Booth', params: { article: element.url } }).catch(err => {
							if (err.name === 'NavigationDuplicated') {
								this.$refs.boothContent.scrollIntoView({ behavior: 'smooth' });
							}
						});
					} else {
						this.activeElement = element;
						this.$refs.contentModal.show();
					}
					this.$gtm.dataLayer().push({
						event: 'gaEvent',
						eCategory: 'Booth Event',
						eAction: element.element,
						eLabel: element.title ? element.title : element.type,
						Exhibitor: this.booth.exhibitor ? this.booth.exhibitor.title : undefined,
						Booth: this.booth.title ? this.booth.title : undefined,
					});
				}
			},
			prerender: prerender,
			rendered() {
				this.$nextTick(() => {
					if (this.$refs.article) {
						let vids = this.$refs.article.$el.getElementsByTagName('video');
						if (vids) {
							for (let v of vids) {
								videojs(v);
							}
						}
					}
				});
			},
			openRating() {
				if (this.ratingActive) {
					this.$modal.show(
						'booth-rating',
						{
							id: this.booth.id,
						},
						{}
					);
				}
			},
			openCardExchange() {
				if (this.cardExchangeActive) {
					this.$modal.show('card-exchange', {
						id: this.booth.id,
					});
				}
			},
			resetBooth() {
				if (this.$route.params.article) {
					this.$router.push({ to: 'Booth', params: { url: this.$route.params.url, article: null } });
				}
			},
			boothRated() {
				this.booth.wasRated = true;
			},
			getElement(index) {
				if (!this.booth || !this.booth.boothElements || this.booth.boothElements.length === 0 || !this.booth.template) return;
				return this.booth.boothElements.find(e => e.element === `element_${index}`);
			},
		},
	};
</script>
