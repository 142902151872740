<template>
	<div class="homepage">
		<div class="lobby-wrapper">
			<div ref="lobby" class="lobby">
				<div class="lobby-background"></div>
				<div class="lobby-foreground" :class="{ 'banner-active': bannerHighlighted }">
					<div class="lobby-banners">
						<template v-for="(banner, index) in lobbyBanners">
							<template v-if="banner">
								<div
									:key="'banner' + banner.id"
									class="lobby-banner"
									:class="[`is-${index + 1}`, { 'is-hovered': bannerHighlighted === index + 1, 'is-other-hovered': bannerHighlighted && bannerHighlighted !== index + 1 }]"
									@mouseenter="highlightBanner(index + 1)"
									@mouseleave="removeHighlight"
									@click="bannerClick(banner)"
								>
									<img :src="banner.image | imageUrl" />
								</div>
								<div
									:key="'title' + banner.id"
									class="lobby-banner-title"
									:class="[`is-${index + 1}`, { 'is-hovered': bannerHighlighted === index + 1, 'is-other-hovered': bannerHighlighted && bannerHighlighted !== index + 1 }]"
									@mouseenter="highlightBanner(index + 1)"
									@mouseleave="removeHighlight"
									@click="bannerClick(banner)"
								>
									<span>{{ banner.title }}</span>
								</div>
							</template>
						</template>
					</div>
					<div class="lobby-menu">
						<Link v-for="item in lobbyMenu" :key="item.id" :link="item.link" :analytics="menuAnalytics(item)">
							<span class="icon"><inline-svg v-if="item.icon" :src="item.icon | imageUrl"></inline-svg></span>
							<span>
								<template v-if="item.title === 'e-Posters'"> <span>e-</span>Posters </template>
								<template v-else>{{ item.title }}</template>
							</span>
						</Link>
					</div>
				</div>
			</div>
			<div class="lobby-mobile">
				<div class="lobby-mobile-menu-left">
					<a @click.prevent="toggleMobileTracks">
						<inline-svg :src="require('../assets/video-call.svg')" width="25" />
					</a>
					<a @click.prevent="toggleMobileClock">
						<inline-svg :src="require('../assets/clock.svg')" width="25" />
					</a>
				</div>

				<div class="lobby-mobile-menu-right">
					<a @click.prevent="toggleMobileMenu">
						<inline-svg :src="require('../assets/quick.svg')" width="25" />
					</a>
					<a @click.prevent="toggleMobileBanners">
						<inline-svg :src="require('../assets/bubble.svg')" width="25" />
					</a>
				</div>

				<div class="lobby-mobile-menu" :class="{ 'is-open': mobileMenu }">
					<h1>
						<inline-svg :src="require('../assets/quick.svg')" width="25" />
						<span>Quick Links</span>
					</h1>
					<a class="lobby-mobile-close" @click.prevent="toggleMobileMenu"><inline-svg :src="require('../assets/delete.svg')" width="25"/></a>
					<div class="lobby-menu">
						<Link v-for="item in lobbyMenu" :key="item.id" :link="item.link" :analytics="menuAnalytics(item)">
							<span class="icon"><inline-svg v-if="item.icon" :src="item.icon | imageUrl"></inline-svg></span>
							<span>
								<template v-if="item.title === 'e-Posters'"> <span>e-</span>Posters </template>
								<template v-else>{{ item.title }}</template>
							</span>
						</Link>
					</div>
				</div>
				<div class="lobby-mobile-banners" :class="{ 'is-open': mobileBanners }">
					<h1>
						<inline-svg :src="require('../assets/bubble.svg')" width="25" />
						<span>Banners</span>
					</h1>
					<a class="lobby-mobile-close" @click.prevent="toggleMobileBanners"><inline-svg :src="require('../assets/delete.svg')" width="25"/></a>
					<div class="lobby-mobile-banners-content">
						<template v-for="(banner, index) in lobbyBanners">
							<template v-if="banner">
								<div class="banner-title-mobile">
									<span>{{ banner.title }}</span>
								</div>
								<div
									:key="'banner' + banner.id"
									class="lobby-banner"
									:class="[`is-${index + 1}`, { 'is-hovered': bannerHighlighted === index + 1, 'is-other-hovered': bannerHighlighted && bannerHighlighted !== index + 1 }]"
									@mouseenter="highlightBanner(index + 1)"
									@mouseleave="removeHighlight"
									@click="bannerClick(banner)"
								>
									<img :src="banner.image | imageUrl" />
								</div>
							</template>
						</template>
					</div>
				</div>
			</div>
		</div>
		<div class="intro-wrapper">
			<div class="intro-subwrapper">
				<VueMarkdown v-if="homepageData && homepageData.introduction" :source="homepageData.introduction" class="intro content" />
			</div>
		</div>
		<!--<LiveTracks />-->
		<!--<ExternalSessions />-->
		<AgendaUpcoming />
		<Sponsors v-if="homepageData" :sponsors="homepageData.sponsors" :title="homepageData.sponsors_title" :subtitle="homepageData.sponsors_subtitle" />
		<Sponsors v-if="homepageData" :sponsors="homepageData.founder_sponsors" :title="homepageData.founder_sponsors_title" :subtitle="homepageData.founder_sponsors_subtitle" />

		<div class="glide-slider-wrapper">
			<div v-if="homepageData.image_slider" ref="hpSlider" class="glide glide-slider">
				<div class="glide__track" data-glide-el="track">
					<ul class="glide__slides">
						<li
							v-for="slide in homepageData.image_slider"
							:key="slide.id"
							class="glide__slide"
							:style="{ backgroundImage: slide.background && `url('${$options.filters.imageUrl(slide.background)}')` }"
						>
							<div class="image-slide-content">
								<VueMarkdown v-if="slide.subtitle" :source="slide.subtitle" class="slide-subtitle" />
								<Link v-if="slide.link" :class="{ 'button is-primary': slide.link.text, 'is-full': !slide.link.text }" :link="slide.link" :analytics="sliderAnalytics(slide)">{{
									slide.link.text
								}}</Link>
							</div>
						</li>
					</ul>
				</div>
				<div class="glide__arrows" data-glide-el="controls">
					<button class="glide__arrow glide__arrow--left" data-glide-dir="<"><inline-svg :src="require('../assets/back.svg')" width="25"></inline-svg></button>
					<button class="glide__arrow glide__arrow--right" data-glide-dir=">"><inline-svg :src="require('../assets/back.svg')" width="25"></inline-svg></button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Link from '../components/Link';
	import $http from '../utils/http.js';
	import Sponsors from '../components/Sponsors';
	import VueMarkdown from 'vue-markdown-v2';
	import meta from '../utils/meta.js';
	import AgendaUpcoming from '../components/AgendaUpcoming';
	import ExternalSessions from '../components/ExternalSessions';
	import LobbyBannerModal from '../components/LobbyBannerModal';
	import { intersectSupported, eventBus } from '../utils/utils';
	import Glide from '@glidejs/glide';

	let lastKnowScrollPos = 0;
	let ticking = false;
	let lobbyElement;

	const resize = () => {
		if (lobbyElement) {
			let initialDims = { width: 2056, height: 1159 };

			if (window.innerWidth < 1024) {
				initialDims = { width: 1024, height: 1159 };
			}

			let parent = lobbyElement.parentNode,
				parentWidth = parent.clientWidth,
				scale = parentWidth / initialDims.width,
				marginBottom = (scale - 1) * initialDims.height;

			lobbyElement.style.transformOrigin = '0 0';
			lobbyElement.style.transform = 'scale(' + scale + ')';

			lobbyElement.style.WebkitTransformOrigin = '0 0';
			lobbyElement.style.WebkitTransform = 'scale(' + scale + ')';

			lobbyElement.style.MozTransformOrigin = '0 0';
			lobbyElement.style.MozTransform = 'scale(' + scale + ')';

			lobbyElement.style.OTransformOrigin = '0 0';
			lobbyElement.style.OTransform = 'scale(' + scale + ')';
			lobbyElement.style.marginBottom = marginBottom - 1 + 'px';
		}
	};

	function shuffle(array) {
		var currentIndex = array.length,
			randomIndex;

		while (0 !== currentIndex) {
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex--;

			[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
		}

		return array;
	}

	const LOBBY_BANNER_COUNT = 2;

	export default {
		name: 'Homepage',
		components: { ExternalSessions, AgendaUpcoming, Sponsors, Link, VueMarkdown },
		data() {
			return {
				homepageData: {},
				lobby: {},
				bannerHighlighted: null,
				scroll: 15,
				swiperOptions: {
					loop: this.homepageData && this.homepageData.image_slider && this.homepageData.image_slider.length > 1,
					autoplay: { delay: 7000 },
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
				},
				mobileMenu: false,
				mobileBanners: false,
			};
		},
		computed: {
			lobbyMenu() {
				if (!this.lobby || !this.lobby.lobby_menu) return false;
				return this.lobby.lobby_menu;
			},
			lobbyBanners() {
				if (!this.lobby || !this.lobby.lobby_banners) return false;
				let banners = this.lobby.lobby_banners;
				if (banners.length < LOBBY_BANNER_COUNT) {
					while (banners.length < LOBBY_BANNER_COUNT) {
						banners.push(null);
					}
				}
				return [...shuffle(this.lobby.lobby_banners)].slice(0, LOBBY_BANNER_COUNT);
			},
		},
		watch: {
			lobbyBanners: 'lobbyBannersChanged',
		},
		beforeRouteEnter(to, from, next) {
			$http
				.all([$http.get(`/homepage`), $http.get(`/lobby`)])
				.then(
					$http.spread((hp, lobby) => {
						next(vm => {
							vm.setData(hp, lobby);
						});
					})
				)
				.catch(() => {
					next();
				});
		},
		beforeRouteLeave(to, from, next) {
			window.sessionStorage.setItem('forceReload', JSON.stringify({ name: to.name, params: to.params }));
			location.reload();
		},
		mounted() {
			meta.setTitle(null);
			lobbyElement = this.$refs.lobby;
			window.addEventListener('resize', resize);
			resize();
		},
		beforeDestroy() {
			window.removeEventListener('resize', resize);
		},
		methods: {
			setData(data, lobby) {
				this.homepageData = data.data;
				this.lobby = lobby.data;

				this.$nextTick(() => {
					if (this.homepageData.image_slider.length > 0) {
						this.glide = new Glide(this.$refs.hpSlider, {
							type: 'slider',
							autoplay: 7000,
							gap: 0,
							swipeThreshold: false,
							dragThreshold: false,
						}).mount();
					}
				});
			},
			sliderClicked(i, index) {
				if (i >= 0) {
					let slide = this.homepageData.image_slider[i];
					if (slide.link) {
						this.$gtm.dataLayer().push({
							event: 'gaEvent',
							eCategory: 'No Booth Event',
							eAction: 'App Carousel',
							eLabel: slide.title ? slide.title : slide.link ? slide.link.text : null,
							Exhibitor: slide.gtm_exhibitor ? slide.gtm_exhibitor : '(not set)',
							Booth: '(not set)',
						});
					}
				}
			},
			highlightBanner(id) {
				this.bannerHighlighted = id;
			},
			removeHighlight() {
				this.bannerHighlighted = null;
			},
			bannerClick(banner) {
				this.$gtm.dataLayer().push({
					event: 'gaEvent',
					eCategory: 'No Booth Event',
					eAction: 'Lobby - Ads left - Click',
					eLabel: banner.title ? banner.title : null,
					Exhibitor: banner.gtm_exhibitor ? banner.gtm_exhibitor : '(not set)',
					Booth: '(not set)',
				});
				this.$modal.show(
					LobbyBannerModal,
					{
						banner,
					},
					{
						classes: 'lobby-banner-modal',
						width: '90%',
						maxWidth: 900,
						height: 'auto',
						adaptive: true,
					},
					{
						opened: this.disableBodyScroll,
						closed: this.enableBodyScroll,
					}
				);
			},
			disableBodyScroll() {
				document.body.classList.add('disabled-scroll');
			},
			enableBodyScroll() {
				document.body.classList.remove('disabled-scroll');
			},
			menuAnalytics(item) {
				return {
					event: 'gaEvent',
					eCategory: 'No Booth Event',
					eAction: 'Lobby - Buttons Right',
					eLabel: item.title,
					Exhibitor: '(not set)',
					Booth: '(not set)',
				};
			},
			sliderAnalytics(slide) {
				return {
					event: 'gaEvent',
					eCategory: 'No Booth Event',
					eAction: 'App Carousel',
					eLabel: slide.title ? slide.title : slide.link ? slide.link.text : null,
					Exhibitor: slide.gtm_exhibitor ? slide.gtm_exhibitor : '(not set)',
					Booth: '(not set)',
				};
			},
			lobbyBannersChanged() {
				if (this.lobbyBanners && this.lobbyBanners.length > 0) {
					for (let banner of this.lobbyBanners) {
						if (banner) {
							this.$gtm.dataLayer().push({
								event: 'gaEvent',
								eCategory: 'No Booth Event',
								eAction: 'Lobby - Ads left - Impression',
								eLabel: banner.title ? banner.title : null,
								Exhibitor: banner.gtm_exhibitor ? banner.gtm_exhibitor : '(not set)',
								Booth: '(not set)',
							});
						}
					}
				}
			},
			toggleMobileMenu() {
				this.mobileMenu = !this.mobileMenu;
			},
			toggleMobileBanners() {
				this.mobileBanners = !this.mobileBanners;
			},
			toggleMobileTracks() {
				eventBus.$emit('toggleMobileTracks');
			},
			toggleMobileClock() {
				eventBus.$emit('toggleMobileClock');
			},
		},
	};
</script>
